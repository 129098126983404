import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { categoriesApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { convertToSlug, translate, truncateText } from "../utils";
import Skeleton from "react-loading-skeleton";
import { settingsData } from '../store/reducers/settingsReducer';
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa";

SwiperCore.use([Navigation, Pagination]);
const CatNav = () => {
    const [Data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openSubmenu, setOpenSubMenu]= useState(null);

    const navigate = useNavigate();
    const currentLanguage = useSelector(selectCurrentLanguage);
    const categoiresOnOff = useSelector(settingsData);

    useEffect(() => {
        categoriesApi(
            "0",
            "40",
            currentLanguage.id,
            (response) => {
                const responseData = response.data;
                setData(responseData);
                setIsLoading(false);
            },
            (error) => {
                if (error === "No Data Found") {
                    setData("");
                    setIsLoading(false);
                }
            }
        );
    }, [currentLanguage]);

    const swiperOption = {
        loop: false,
        speed: 750,
        spaceBetween: 10,
        slidesPerView: "auto",
        navigation: false,
        breakpoints: {
            1200: {
                slidesPerView: 11,
            },
        },
        autoplay: false,
    };

    const handleToggleSubmenu = (index) => {
        setOpenSubMenu((prev) => (prev === index ? null : index));
    };


    return (
    <>
        { categoiresOnOff && categoiresOnOff.category_mode === "1" ?
            <div>
                {Data.length > 0 ? (
                    <div id="cn-main" expand="lg">
                        <div className="container py-2">
                            {isLoading ? (
                                <div>
                                    <Skeleton height={200} count={3} />
                                </div>
                            ) : (
                                <Swiper {...swiperOption} style={{ overflow: 'visible' }}>
                                    {Data.slice(0, 11).map((element, index) => (
                                        <SwiperSlide key={element.id} className="text-center">
                                            {element.subcategory?.length > 0 ? 
                                                (
                                                    <button id="catNav-links" className="text-center bg-transparent" onClick={()=> handleToggleSubmenu(index)}>
                                                        <b>{truncateText(element.category_name, 20)} &nbsp;
                                                            {openSubmenu === index? 
                                                                <FaChevronRight style={{ alignSelf: 'center' }}/> 
                                                                : <FaChevronDown style={{ alignSelf: 'center' }} />
                                                            }
                                                        </b>
                                                    </button>
                                                ) : (
                                                    <Link id="catNav-links" to={`/categories/${element.id}`}>
                                                        <b>{truncateText(element.category_name, 20)}</b>
                                                    </Link>
                                                )
                                            }
                                            {openSubmenu === index && (
                                                <ul
                                                    style={{
                                                        listStyle: 'none',
                                                        margin: '10px 0 0',
                                                        padding: '10px',
                                                        backgroundColor: '#f9f9f9',
                                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                                        borderRadius: '5px',
                                                        position: 'absolute',
                                                        top: '25px',
                                                        minWidth: 200,
                                                        display: "flex",
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        left: '0',
                                                        zIndex: 10,
                                                    }}
                                                >
                                                    {element.subcategory.map((sm) => (
                                                        <li
                                                            key={sm.id}
                                                            style={{
                                                                padding: '5px 10px',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={()=> setOpenSubMenu(null)}
                                                        >
                                                            <Link className="text-dark text-decoration-none" to={`/categories/${element.id}/sub/${sm.id}`}>
                                                                <b>{sm.subcategory_name}</b>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </SwiperSlide>
                                    ))}
                                    {Data.length > 10 && (
                                        <SwiperSlide className="text-center">
                                            <button
                                                id="catNav-links"
                                                onClick={() => {
                                                    navigate("/categories");
                                                }}
                                            >
                                                {translate("More >>")}
                                            </button>
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
                :
                null
        }
    </>
    );
};

export default CatNav;
